import React, { Fragment, useState } from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import From from 'common/components/form/new-form'
import * as routes from 'config/routes'
import { Link } from 'components/link'
import { TextInputField } from 'components/form/text-input'
// import InvisibleRecaptcha from 'components/invisible-recaptcha';
import { ModalAuth } from './modalAuth'
import * as config from 'config'

export function FormSignIn(props) {
  // const recaptchaRef = React.createRef();
  const { onClose = () => {} } = props
  const [form, setForm] = useState({
    email: props.common.signInModal.email,
    password: '',
  })
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)

  const [emailValid, setEmailValid] = useState(false)
  const [passwordValid, setPasswordValid] = useState(false)

  const onRecaptchaPassed = result => {
    props.actions.user
      .signIn(form)
      .then(resp => {
        resp, onClose()
      })
      .catch(err => {
        const { body } = err.response
        setLoading(false)
        if (body && body.errors) {
          const errors = body.errors
            .map(error =>
              props.common.strings[error.code]
                ? props.common.strings[error.code]
                : error.code
            )
            .filter(Boolean)

          setErrors(errors)
          switch (errors.toString()) {
            case 'user_need_2FA':
              props.actions.common.openAuthModal()
              break
            case 'User is deactivated':
              onClose()
              break
            default:
              break
          }
        }
        throw err
      })
  }

  const onChange = (ev, form) => {
    setErrors([])
    setForm(form)
  }
  const onSubmit = (ev, form) => {
    setLoading(true)
    onRecaptchaPassed()
    // recaptchaRef.current.execute(config.RECAPTCHA_KEY, {
    //     action: 'defaultAction'
    // })
  }

  const closeModal = () => {
    props.actions.common.closeAuthModal()
  }

  const { strings } = props.common
  const buttonClassName = Component.classList(
    'button button--free',
    loading && '_waiting'
  )
  const signInFormClassName = Component.classList(
    'sign-in-form j-terms',
    loading && '_loading'
  )

  const oauth = type => {
    if (type === 'facebook') {
      window.location.assign(`${config.API_HOST}/users/auth/facebook`)
    }
    if (type === 'google') {
      window.location.assign(`${config.API_HOST}/users/auth/google_oauth2`)
    }
    if (type === 'twitter') {
      window.location.assign(`${config.API_HOST}/users/auth/twitter`)
    }
  }

  const handleEmailValidation = isValid => {
    setEmailValid(isValid)
  }

  const handlePasswordValidation = isValid => {
    setPasswordValid(isValid)
  }

  return (
    <Fragment>
      <span className="sign-in__title">{strings['signin.title']}</span>
      <div className="sign-in-text sign-in-text--pre">
        <p>{strings['signin.text']}</p>
      </div>
      <div className={signInFormClassName}>
        <From onChange={onChange} onSubmit={onSubmit}>
          <TextInputField
            isEmail
            name="email"
            title={strings['signin.email.placeholder']}
            type="email"
            value={form.email}
            onValidate={handleEmailValidation}
          />
          <TextInputField
            autoComplete="new-password"
            isRequired
            name="password"
            title={strings['signin.password.placeholder']}
            type="password"
            value={form.password}
            onValidate={handlePasswordValidation}
          />

          {errors.map(error => (
            <div key={error} className="error">
              {error === 'user_need_2FA'
                ? strings['header.profile.two_auth.hint']
                : error}
            </div>
          ))}

          <div className="field field--padded">
            <button
              type="submit"
              className={buttonClassName}
              disabled={!emailValid || !passwordValid}
            >
              {strings['signin.button.text']}
            </button>
          </div>
        </From>
      </div>

      <div className="sign-in-text">
        <p>
          <Link onClick={() => onClose()} to={routes.restorePassword.getPath()}>
            {strings['signin.forgot']}
          </Link>
        </p>
      </div>

      {!config.IS_LANDING && (
        <div className="sign-in-text sign-in-text--pre">
          <p>
            {strings['signin.notes']}{' '}
            <a href="mailto:service@ruptly.video">service@ruptly.video</a>
          </p>
        </div>
      )}

      {props.common.AuthModal && (
        <ModalAuth close={closeModal} confirm={onSubmit} />
      )}
    </Fragment>
  )
}

export default DI(['user', 'recaptcha'])(FormSignIn)
