import * as C from './consts'
import * as videos from './actions'
import { replace } from 'connected-react-router'
import { videoView } from 'config/routes'
import { cyrillicTitle } from 'helpers/strings'

export default ({ getState, dispatch }) => {
  return next => action => {
    if (action.type === C.CLEAR_LIST_VIDEOS) {
      const {
        common: { searchParams },
      } = getState()

      dispatch({
        type: C.GET_LIST.SUCCESS,
        data: {
          count: 0,
          locales_result: [],
          videos: [],
        },
        search: { ...searchParams, page: 1 },
      })
    }

    if (action.type === C.DOWNLOAD_VIDEO.SUCCESS) {
      const state = getState()
      const { strings, locale } = state.common

      const defineAlert = (title, text, popup = false, closeTimeout = 5000) => {
        dispatch(videos.setPaymentAlert({ title, text, popup }))

        setTimeout(() => {
          dispatch(videos.setPaymentAlert(null))
        }, closeTimeout)
      }
      const title = cyrillicTitle(
        state.videos.byID.getValue(action.search.project_slug).payload.title
      )

      dispatch(
        replace(
          videoView.getPath(locale, action.search.project_slug) + '-' + title
        )
      )

      if (
        action.search.agreement_type === 'one_off' &&
        !action.search.repeated
      ) {
        if (action.search.video_format.brand_position === 'without_brand') {
          defineAlert(
            strings['alert.payment.success.oneoff.line1'],
            strings['alert.payment.success.oneoff.line2']
          )
        } else {
          defineAlert(
            strings['alert.payment.success.payed.branded.line1'],
            strings['alert.payment.success.payed.branded.line2'],
            !action.data.exist,
            15 * 1000
          )
        }
      } else {
        if (
          action.data.exist ||
          (action.search.video_format.brand_position === 'without_brand' &&
            action.search.video_format.file_type !== 'mxf')
        ) {
          defineAlert(
            strings['alert.payment.success.free.nonbranded.line1'],
            strings['alert.payment.success.free.nonbranded.line2']
          )
        } else {
          defineAlert(
            strings['alert.payment.success.free.branded.line1'],
            strings['alert.payment.success.free.branded.line2'],
            !action.data.exist,
            15 * 1000
          )
        }
      }

      if (action.data.exist) {
        setTimeout(() => {
          const iframe = document.createElement('iframe')
          iframe.src = action.data.url

          document.body.appendChild(iframe)
        }, 100)
      }
    }

    return next(action)
  }
}
